.modal-container {
  padding: 20px;
  background: white;
  margin: auto;
  width: 400px;
  margin-top: 5%;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: "Montserrat";
}

.modal-containerBig {
  padding: 20px;
  background: white;
  margin: auto;
  width: 900px;
  margin-top: 5%;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: "Montserrat";
}

.modal_add {
  width: 370px;
  height: 270px; // обмеження висоти списку
  overflow: auto; // додання прокрутки
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-left: 10px;
  margin: 1px 0;
  display: flex;
  flex-direction: column; // Розташування кожного елемента у вертикальному напрямку
  gap: 8px; // Відступ між елементами
}

.modal_add .MuiCheckbox-root {
  padding: 0px; /* Зменшення внутрішніх відступів у чекбоксі */
  padding-left: 20px;
  padding-right: 10px;
}

.modal-actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  // padding: 20px 0px 10px 10px;
  border-bottom: 1px solid;
  transition: all ease;
  font-weight: 400;
  font-family: "Montserrat";
}