@import '/src/theme/styles/index.scss';
// @import '/src/theme/styles/fonts.css';

.Accordion {
  $this: &;
  margin-top: 10px;
  box-shadow: none;
  padding: 0px;

  &-Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 0px 20px 0px 50px;
    background-color: $color-white;
    border-bottom: 1px solid $color-suit-gray;
    transition: all $transition ease;
    font-weight: 400;
    font-family: "Montserrat";
    font-size: 1;

    &Titel {
      margin-left: 10px;
      font-size: 1.5rem;
    }

    &:hover {
      cursor: pointer;
      box-shadow: $shadow-bottom;
    }

    &:last-child {
      margin-top: 0;
    }

    &Icon {
      svg {
        transition: all $transition ease;
        width: 16px;
        height: 10px;
      }
    }
  }

  &-ContentWrapper {
    &-enter {
      opacity: 0;
      overflow: hidden;
      max-height: 0;
    }

    &-enter-active {
      opacity: 1;
      max-height: var(--content-height);
      transition: opacity $transition, max-height $transition cubic-bezier(0, 1, 0, 1);
    }

    &-exit {
      opacity: 1;
      max-height: var(--content-height);
    }

    &-exit-active {
      overflow: hidden;
      opacity: 0;
      max-height: 0;
      transition: opacity $transition, max-height $transition cubic-bezier(0, 1, 0, 1);
    }
  }

  &-Content {
    background-color: $color-white;
    overflow: hidden;
    padding: 0px 0px 0px 10px;
    border-width: 0px;
    border-left: 0px solid transparent;
    border-right: 50px solid transparent;
    border-top: 1px solid transparent;
    transition: all $transition;
  }

  .row-inactive {
    text-decoration: line-through;
    background-color: $color-suit-gray;
  }
  
  .link-no-underline {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
  }

  &#{$this}__active {
    #{$this}-HeaderIcon {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.theme-dark {
  .Accordion {
    &-Header {
      background-color: $color-darkness;
    }

    &-Content {
      background-color: $color-darkness;
    }
  }
}