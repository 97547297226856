.bulkOperations {
  position: relative;
}

.bulkActions {
  padding-left: 4px;
  padding-right: 4px;
  margin-top: 6px;
  position: absolute;
  left: 50px;
  width: 100%;
  z-index: 2;
  background-color: white;
}

.bulkAction {
  margin-left: 8px;
}

.filtersContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.formControl {
  min-width: 150px;
}

.dialogContent {
  display: flex;
  justify-content: space-between;
}

.filterColumn {
  width: 100%;
  height: 70%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.dialogActions {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.iconButton {
  cursor: pointer;
  transition: background-color 0.3s;
}

.accordion {
  width: 100%;
}

.accordionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.searchFieldContainer {
  margin-left: auto;
}
