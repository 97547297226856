.MuiTableCell-root:empty {
  display: none;
}
.table-cell {
  padding: 0px;
  $this: &;
  &-id {
    width: 10%;
    text-align: left;
  }
  &-percent {
  width: 20%;
    text-align: left;
  }
  &-description {
    width: 50%;
    text-align: left;
  }
  &-supplier {
    width: 20%;
    text-align: left;
  }
  &-icon {
    text-align: right;
    padding: 0px;
    padding-right: 10px;
  }
}
  .modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  // padding: 20px 0px 10px 10px;
  border-bottom: 1px solid;
  transition: all ease;
  font-weight: 400;
  font-family: "Montserrat";
}