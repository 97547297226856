@layer base {
    @font-face {
      font-weight: 400;
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-display: swap;
      src: url("https://fonts.googleapis.com/css2?family=Montserrat") format("stylesheet");
    }
  
    @font-face {
      font-weight: 500;
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-display: swap;
      src: url("https://fonts.googleapis.com/css2?family=Montserrat") format("stylesheet");
    }
  
    @font-face {
      font-weight: 600;
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-display: swap;
      src: url("https://fonts.googleapis.com/css2?family=Montserrat") format("stylesheet");
    }
  
    @font-face {
      font-weight: 700;
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-display: swap;
      src: url("https://fonts.googleapis.com/css2?family=Montserrat") format("stylesheet");
    }
  }