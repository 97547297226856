$color-primary: rgba(176, 151, 106, 1); /* #B0976A */
$color-primary-hover: rgba(153, 126, 77, 1); /* #997E4D */
$color-primary-light: rgba(239, 234, 225, 1); /* #efeae1 */
$color-primary-light-hover: rgba(223, 213, 195, 1); /* #dfd5c3 */

$color-disabled: rgba(191, 191, 191, 1); /* #bfbfbf */
$color-disabled-secondary: rgba(245, 245, 245, 1); /* #f5f5f5 */

$color-text: rgba(51, 51, 51, 1); /* #333 */

/* White */
$color-white: rgba(255, 255, 255, 1); /* #fff */

/* Dark */
$color-black: rgba(0, 0, 0, 1); /* #000 */
$color-night: rgba(24, 24, 31, 1); /* #18181f */
$color-darkness: rgba(31, 32, 41, 1); /* #1f2029 */
$color-nightSky: rgba(35, 40, 56, 1); /* #232838 */
$color-lighting: rgba(138, 143, 160, 1); /* #8a8fa0 */

/* Gray */
$color-grey: rgba(245, 245, 246, 1); /* #f5f5f6 */
$color-dark-gray: rgba(185, 181, 181, 1); /* #B9B5B5 */
$color-light-gray: rgba(228, 228, 228, 1); /* #e4e4e4 */
$color-mercury-gray: rgba(232, 232, 232, 1); /* #e8e8e8 */
$color-silver-gray: rgba(191, 191, 191, 1); /* #bfbfbf */
$color-suit-gray: rgba(142, 142, 147, 1); /* #8e8e93 */
$color-suit-gray-hover: rgba(134, 134, 134, 0.5); /* #bab9b9 */
$color-alto-gray: rgba(87, 87, 87, 1); /* #dedede */

/* Violet */
$color-light-violet: rgba(126, 87, 194, 1); /* #7E57C2 */
$color-dark-violet: rgba(101, 45, 187, 1); /* #652DBB */

/* Red */
$color-red: rgba(227, 18, 53, 1); /* #e31235 */
$color-light-red: rgba(251, 228, 227, 1); /* #fbe4e3 */
$color-light-red-hover: rgba(250, 219, 218, 1); /* #fadbda */

/* Other */
$color-bg-input: rgba(236, 241, 247, 1); /* #ecf1f7 */
$color-bg-input-hover: rgba(228, 235, 243, 1); /* #e4ebf3 */