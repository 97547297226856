.Icon {
  display: inline-flex;
  align-items: center;
  text-align: center;

  svg {
    display: inline-block;
    width: 100%;
    height: max-content;
  }
}